import React from 'react';
import Post from '../components/post';
import EvenFinancialWidget from '../components/EvenFinancialWidget';
import LazyLoadImage from '../components/LazyLoadImage';


const HowToGetOutOfDebtPage = () => {
    const article = {
        id: 'b898118a-18d8-5bfa-89de-0ee48377a2c6',
        title: 'How to Get Out of Debt',
        slug: '/how-to-get-out-of-debt/',
        date: '2018-01-22T20:46:12.000Z',
        modified: '2021-09-16T17:52:17.000Z',
        excerpt: 'Learn more about the different kinds of debt, as well as how to tackle them. For even further reading, we&#8217;ve included an exhaustive list of the best resources for dealing with debt.',
        featured_image: {
            source_url: 'https://www.debtconsolidation.com/media/how-to-get-out-of-debt.jpg',
        },
        acf: {
            seo_title: '',
            robots: 'index, follow',
        },
        read_time_minutes: 7,
        content: (
            <>
                <h2 id="what-is-debt">What is Debt?</h2>
                {' '}
                <p>
                    Debt is simply the amount of money or property that one party owes to another party. The parties can be individuals, corporations, municipalities or even governments. Consumer debt (or
                    {' '}
                    <a href="http://www.federalreserve.gov/releases/g19/Current/">outstanding consumer credit</a>
                    ) is the amount owed by a consumer to a merchant or service provider. This debt includes everything from credit cards to mortgage loans.
                </p>
                {' '}
                <p><strong>The average U.S. consumer owes more than:</strong></p>
                {' '}
                <ul className="list">
                    {' '}
                    <li>$15,112 in credit card debt</li>
                    {' '}
                    <li>$146,215 in mortgage debt</li>
                    {' '}
                    <li>
                        $31,240 in
                        {' '}
                        <a href="https://www.ac934intrk.com/3J67C/3K6X8K/?uid=44&amp;sub1=how-to-get-out-of-debt&amp;sub2=student-loan" rel="noopener noreferrer" target="_blank">student loan</a>
                        {' '}
                        debt
                    </li>
                    {' '}
                </ul>
                {' '}
                <p>The total U.S. consumer debt is $11.4 trillion dollars.</p>
                {' '}
                <p>
                    <LazyLoadImage src="https://www.debtconsolidation.com/media/us-national-debt-0.png" alt="us national debt" />
                </p>
                {' '}
                <h2 id="problem-debt">Problem Debt</h2>
                {' '}
                <p>Not all debt is bad; many financial experts define debt as either good or bad, depending on how it is used.</p>
                {' '}
                <p>The following are a few articles that describe the differences between the two.</p>
                {' '}
                <ul className="list">
                    {' '}
                    <li>
                        CNN Money:
                        {' '}
                        <a href="http://money.cnn.com/magazines/moneymag/money101/lesson9/index2.htm">Good versus Bad Debt</a>
                    </li>
                    {' '}
                    <li>
                        Forbes Online:
                        {' '}
                        <a href="http://www.forbes.com/sites/panosmourdoukoutas/2012/11/24/good-debt-bad-debt/">Good Debt Bad Debt</a>
                    </li>
                    {' '}
                    <li>
                        About.com:
                        {' '}
                        <a href="http://credit.about.com/od/avoidingdebt/a/goodvsbaddebt.htm">Good Debt vs. Bad Debt</a>
                    </li>
                    {' '}
                </ul>
                {' '}
                <h3>Why So Much Debt?</h3>
                {' '}
                <p>
                    <LazyLoadImage src="https://www.debtconsolidation.com/media/outstanding-home-mortgage-debt-1.png" alt="Outstanding home mortgage debt" />
                </p>
                {' '}
                <p>Debt happens for a variety of reasons.</p>
                {' '}
                <p>To begin with, many transactions only happen through the use of credit – whether revolving credit, secured and unsecured loans or other lines.</p>
                {' '}
                <p>Using debt allows people to purchase items that would otherwise be out of reach.</p>
                {' '}
                <p>
                    <strong>Problem debt</strong>
                    , however, happens when payments can&rsquo;t be made.
                </p>
                {' '}
                <p>
                    When individuals are laid-off, sick or otherwise financially unable to make payments, this debt adds up to unsustainable levels.
                    {' '}
                    <a href="https://www.debtconsolidation.com/bankruptcy/">Homes, jobs and even health can all be lost</a>
                    .
                </p>
                <h2 id="getting-out-of-debt">Getting Out of Debt</h2>
                <p>One of the biggest challenges in life can be to get out of debt, especially since the economy is largely debt-based.</p>
                <p>The easiest way to make this happen is to earn more money, receive an inheritance or win the lottery. However, these are not reliable options.</p>
                <p>Many people look for professional help to get them through their situation. After all, being in debt is traumatic.</p>
                <p>This help can come from legal professional, credit counselors or debt relief providers and often include:</p>
                <ul className="list">
                    <li>Bankruptcy</li>
                    <li>Debt Settlement</li>
                    <li>Credit Counseling</li>
                    <li>Debt Consolidation</li>
                </ul>
                <p>
                    While many strategies can be used, depending on the unique needs of the individual, the primary categories include
                    {' '}
                    <strong>debt reduction</strong>
                    ,
                    {' '}
                    <strong>debt management</strong>
                    {' '}
                    and
                    {' '}
                    <strong>debt consolidation.</strong>
                </p>
                <p>
                    <LazyLoadImage src="https://www.debtconsolidation.com/media/top-foreign-holders-of-us-debt-2.png" alt="Top Foreign Holders of US Debt" />
                </p>
                <h3>Debt Reduction</h3>
                <p>
                    Debt reduction services usually offer
                    {' '}
                    <a href="http://www.consumer.ftc.gov/articles/0153-choosing-credit-counselor">financial counseling</a>
                    {' '}
                    and debt management assistance for consumers. This includes assistance with budgeting, planning and saving.
                </p>
                <p>
                    Credit.com offers a
                    {' '}
                    <a href="http://www.credit.com/products/debt/Debt-Reduction-Do-It-Yourself.jsp">&ldquo;DIY&rdquo; plan for reducing debt</a>
                    , and Fox Business suggests
                    {' '}
                    <a href="http://www.foxbusiness.com/personal-finance/2012/05/09/how-to-create-debt-reduction-plan">strategizing a payback plan</a>
                    {' '}
                    and sticking to it.
                </p>
                <p>
                    About.com lists other helpful &ldquo;
                    <a href="http://frugalliving.about.com/od/moneymanagement/a/Smart_Debt_Move.htm">smart money moves</a>
                    &rdquo; for consumers to get out from under debt.
                </p>
                <p>
                    Debt reduction calculators, such as the
                    {' '}
                    <a href="http://money.cnn.com/calculator/pf/debt-free/">CNN debt reduction calculator</a>
                    , can help consumers figure out when they will be debt free and how much they will pay in interest by paying just the monthly minimums.
                </p>
                <p><strong>&ldquo;Do it Yourself&rdquo; Debt Reduction Strategies:</strong></p>
                <ul className="list">
                    <li>The Every Girl</li>
                    <li>CoupleMoney.com</li>
                    <li>NoMoreDebts.org</li>
                    <li>PersonalFinance.byu.edu</li>
                    <li>CNN Money</li>
                    <li>LifeHacker.com</li>
                    <li>The Motley Fool</li>
                    <li>Century Negotiations Inc.</li>
                    <li>AccumulatingMoney.com</li>
                    <li>Everyday Simple Living</li>
                    <li>Debt Consolidation Care</li>
                    <li>CreditCards.com</li>
                    <li>RealSimple.com</li>
                </ul>
                <p><strong>Fee-based and Nonprofit Debt Reduction Companies and Services:</strong></p>
                <ul className="list">

                    <li>DebtReductionServices.org</li>

                    <li>Better Business Bureau</li>

                    <li>Our Credit Counseling Services</li>

                    <li>MoneyManagement.org</li>

                    <li>Alliance Credit Counseling</li>

                    <li>In Charge Debt Solutions</li>

                </ul>

                <h3>Debt Management</h3>

                <p>Debt management companies are similar to debt relief companies, but they are not quite the same.</p>

                <p>The most common type of debt management company is a credit counseling firm.</p>

                <p>Credit counseling firms help people set budgets and often set up debt management plans.</p>

                <p>
                    A debt management plan (DMP) will take
                    {' '}
                    <a href="/debt-management/">one monthly bill payment from a consumer and allocate it to all creditors</a>
                    {' '}
                    that are owed money that month.
                </p>

                <p>
                    Financial expert Dave Ramsey states that typically, the debt management companies
                    {' '}
                    <a href="http://www.daveramsey.com/article/the-truth-about-debt-management/">will negotiate lower payments and interest rates with creditors in advance</a>
                    , which is why they are able to spread one payment into many.
                </p>

                <p>Usually, debt management companies will run an income and expenditure test on a household to see how much can be allocated towards debt each month.</p>

                <p>
                    Some organizations are non-profits that charge at no or non-fee rates,
                    {' '}
                    <a href="http://www.consumer.ftc.gov/articles/0153-choosing-credit-counselor">while others can be quite expensive</a>
                    , according to a report by the Federal Trade Commission.
                </p>

                <p>Knowing the basics of how these companies work is important in order to pick the most appropriate debt management plan.</p>

                <p><strong>Non-profit and for-profit organizations offering debt management plans:</strong></p>

                <ul className="list">
                    <li>Money Management International</li>
                    <li>Green Path Debt Solutions</li>
                    <li>ConsumerCredit.com</li>
                    <li>In Charge Debt Solutions</li>
                    <li>Consumer Credit Counseling Services</li>
                    <li>Balance Financial Fitness Program</li>
                    <li>Clear Point Credit Counseling Solutions</li>
                    <li>Step Change Debt Charity Counseling Services</li>
                    <li>CredAbility Nonprofit Credit Counseling and Education</li>
                    <li>Lutheran Social Services of Minnesota Financial Counseling</li>
                    <li>PayPlan Debt Help and Advice</li>
                    <li>CareOne Debt Relief Services</li>
                    <li>Debt Management Credit Counseling Corp.</li>
                </ul>
                <h3>Debt Consolidation</h3>
                <p>
                    Debt consolidation is the act taking out one large
                    {' '}
                    <a href="https://www.ac934intrk.com/3J67C/3K6X8K/?uid=43&amp;sub1=how-to-get-out-of-debt&amp;sub2=loan" rel="noopener noreferrer" target="_blank">loan</a>
                    {' '}
                    to pay off several other loans.
                </p>
                <p>Multiple unsecured debts are consolidated into one monthly payment — usually at a lower interest rate.</p>
                <p style={{
                    fontSize: '1.71428571rem', lineHeight: '1.28571429em', fontWeight: 700, marginTop: '31.5917px', marginBottom: '5px', textAlign: 'center',
                }}
                >
                    See If You Qualify for a Personal&nbsp;Loan up&nbsp;to&nbsp;$250k
                </p>
                <EvenFinancialWidget url="https://embed.hifiona.com/ui/multi-product/index.html?partner=debtconsolidationembed&amp;access_token=628217c7-f4c2-4006-a2b1-3031b4c21272_8362ee6d-9497-4440-aaf2-8c6e5685f90b&amp;company_uuid=65d1a0bd-1cab-43c4-b755-2a6bff913a03&amp;productType=loan&amp;pw_fiona=true&amp;available_products=loan" slug="how-to-get-out-of-debt" />

                <br />

                <p>
                    Only unsecured debts are eligible for debt consolidation, that is, loans not backed by an asset such as credit card, medical, and utility bills or
                    {' '}
                    <a href="https://www.ac934intrk.com/3J67C/3K6X8K/?uid=44&amp;sub1=how-to-get-out-of-debt&amp;sub2=student-loan" rel="noopener noreferrer" target="_blank">student loans</a>
                    .
                </p>

                <p>Most consumers who choose to consolidate their debt are looking to either reduce their monthly payments or hasten their debt repayment.</p>

                <h2 id="credit-card-debt">Credit Card Debt</h2>

                <p>
                    <LazyLoadImage src="https://www.debtconsolidation.com/media/credit-card-holders-in-debt-3.png" alt="Credit card holders in debt" />
                </p>

                <p>Credit card debt is one of the most common types that consumers owe and is eligible for consolidation.</p>

                <p>
                    When you obtain a consolidation loan, all credit card debt will be lumped into one loan or payment. Consolidating credit card debt does
                    {' '}
                    <em>not</em>
                    {' '}
                    eliminate any portion of what is owed.
                </p>

                <p>
                    CreditCards.com released &ldquo;
                    <a href="http://www.creditcards.com/credit-card-news/help/9-things-about-debt-consolidation-6000.php">9 Things You Must Know about Credit Card Consolidation</a>
                    &rdquo; to explain the process.
                </p>

                <p>There are both consolidation programs and loans in order to consolidate credit card debt.</p>

                <p>Credit scores are a factor when lenders are considering granting a consolidation loan.</p>

                <p>There are balance transfer credit cards available, which allow a consumer to put all credit card debt onto one card and self-consolidate.</p>

                <p>A consumer might be able to use one of the cards that they already own, as long as there is enough available credit to accommodate for all other debt.</p>

                <h3>Other resources for credit card debt consolidation include:</h3>

                <ul className="list">

                    <li>BankRate.com</li>

                    <li>About.com: Credit</li>

                    <li>LifeHack.org</li>

                    <li>LifeHacker.com</li>

                    <li>WikiHow</li>

                </ul>

                <h2 id="student-loans">Student Loans</h2>

                <p>
                    <a href="https://www.ac934intrk.com/3J67C/3K6X8K/?uid=44&amp;sub1=how-to-get-out-of-debt&amp;sub2=student-loan" rel="noopener noreferrer" target="_blank">Student loan</a>
                    {' '}
                    debt continues to be a major and growing portion of U.S. consumer debt.
                </p>


                <p>
                    When considering whether or not to consolidate your student loans, using the
                    {' '}
                    <a href="http://studentaid.ed.gov/repay-loans/consolidation#checklist">Federal Student Aid&rsquo;s consolidation checklist</a>
                    {' '}
                    can be helpful.
                </p>

                <p>Since not every type of student loan can be consolidated, you should learn which loans are eligible and which aren&rsquo;t.</p>

                <p>The interest rate on a consolidated student loan will be fixed for the life of the loan, although the rate generally should not be higher than 8.25%.</p>

                <p>The repayment period is typically between 10 and 30 years.</p>

                <p>Sometimes when you consolidate student loans, you&rsquo;ll have the option to repay them on an income-based repayment plan.</p>

                <p>For more articles, how-to&rsquo;s, services, and information on consolidating student loans access the following resources:</p>

                <ul className="list">

                    <li>Federal Direct Consolidation Loans Information Center</li>

                    <li>FinAid.org: The SmartStudent Guide to Student Loan Consolidation</li>
                    <li>Wells Fargo Private Student Loan Consolidation Services</li>

                    <li>Forbes: Tips on Consolidation Student Loans</li>

                    <li>CuGrad: Private Student Loan Consolidation</li>

                    <li>Suze Orman&rsquo;s Advice on Consolidating Student Loans</li>

                    <li>US News: 4 Reasons to Consolidate your Student Loans</li>

                    <li>BankRate.com: What to Know about Consolidating Student Loans</li>

                    <li>StudentLoans.gov: Special Direct Consolidation Loans</li>

                    <li>Fox Business: What to Know Before Consolidating Student Loans</li>

                </ul>

                <h2 id="company-ratings-and-reviews">Company Ratings and Reviews</h2>

                <p>
                    <LazyLoadImage src="https://www.debtconsolidation.com/media/ownership-of-us-treasury-debt-4.png" alt="Ownership of US Treasury Debt" />
                </p>

                <p>
                    <LazyLoadImage src="https://www.debtconsolidation.com/media/official-versus-private-debt-holdings-5.png" alt="Official versus private debt holdings" />
                </p>

                <p>When searching for a company to help you with debt consolidation, reading reviews of the services they offer is highly recommended.</p>

                <p>Although online reviews can be one-sided, it is usually in the consumers best interest to avoid companies with poor ratings.</p>

                <p><strong>The following resources rank and rate companies nationwide:</strong></p>

                <ul className="list">

                    <li>2013 Best Debt Consolidation Services Reviews</li>

                    <li>Debt Consolidation Company Reviews and Information</li>

                    <li>Top Consumer Reviews of Debt Relief Companies</li>

                    <li>Consumer Affairs Freedom from Debt Relief User Reviews &amp; Complaints</li>

                    <li>Debt Relief of America Complaints &amp; Reviews</li>

                    <li>Spring Leaf Financial Services Reviews</li>

                    <li>Christian Debt Relief Reviews</li>

                    <li>DailyStrength.org User Reviews on Debt Consolidation</li>

                    <li>Debt Consolidation Programs: Where to Find the Best Deal</li>

                    <li>Better Business Bureau: National Debt Relief Review</li>

                </ul>

                <p>
                    If you&rsquo;re looking for more ways to get yourself out of debt, check out our
                    {' '}
                    <a href="https://www.debtconsolidation.com/4-keys-out-of-debt/">4 Keys to Getting Out of Debt</a>
                    .
                </p>
            </>
        ),
    };

    return (
        <Post article={article} />
    );
};

export default HowToGetOutOfDebtPage;
